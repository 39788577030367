<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori Inventory</b> yang ada di Klinik IONA
      </div>
      <!-- <b-button
        v-if="btn == true"
        squared
        variant="primary"
        @click="handleUploadAll"
        >Unggah Kategori</b-button
      > -->
      <b-button
        class="ml-2"
        v-if="btn == true"
        squared
        variant="success"
        v-b-modal.modal-create
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Kategori"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'actions' ? '20%' : '',
                    width: field.key === 'name' ? '15%' : '',
                    width: field.key === 'description' ? '45%' : '',
                  }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="btnEditOnClick(data.item.id)"
                  v-b-modal.modal-edit
                  v-if="btn"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="btnDeleteOnClick(data.item.id)"
                  v-if="btn"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal Create -->
    <b-modal
      id="modal-create"
      title="Tambah Kategori Inventory"
      @ok="modalAddMedicineCategory"
      @hidden="resetModal"
    >
      <b-form @submit.stop.prevent="addMedicineCategory">
        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            required
            placeholder="Nama Kategori Inventory"
          >
          </b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Description -->
        <b-form-group id="input-group-description">
          <label for="input-description"
            >Deskripsi: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Deskripsi Kategori Inventory"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{ error.description }}</small>
        </b-form-group>
        
      </b-form>
    </b-modal>

    <!-- Modal Edit -->
    <b-modal
      id="modal-edit"
      title="Edit Kategori Inventory"
      @ok="modalEditMedicineCategory"
      @hidden="resetModal"
    >
      <b-form @submit.stop.prevent="editMedicineCategory">
        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            required
            placeholder="Nama Kategori Inventory"
          >
          </b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Description -->
        <b-form-group id="input-group-description">
          <label for="input-description"
            >Deskripsi: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Deskripsi Kategori Inventory"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{ error.description }}</small>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      statusUpload:"Kategori Tidak DiUnggah",
      // Form
      form: {
        name: "",
        description: "",
        is_upload:false,
      },
      // Error
      error: {
        name: "",
        description: "",
      },
      // Options
      doctors: [],
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi", thClass: "nowrap", tdClass: "nowrap" },
      ],
      // Table Items
      items: [],
      // Other
      editTarget: "",
      // User Access
      btn: false,
    };
  },

  methods: {
    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "inventory-categories",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    filterByName() {
      this.pagination();
    },

    async btnEditOnClick(id) {
      this.editTarget = id;
      this.form = await module.get("inventory-categories/" + id);
      this.form["_method"] = "put";
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("inventory-categories/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async addMedicineCategory() {
      let response = await module.submit(this.form, "inventory-categories");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // Hide Modal
        this.$bvModal.hide("modal-create");
        // Refresh Table
        this.pagination();
      }
    },

    async editMedicineCategory() {
      let response = await module.submit(
        this.form,
        "inventory-categories/" + this.editTarget
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // Hide Modal
        this.$bvModal.hide("modal-edit");
        // Refresh Table
        this.pagination()(
          // restore data default
          (this.editTarget = "")
        ),
          delete this.form["_method"];
      }
    },

    modalAddMedicineCategory(evt) {
      evt.preventDefault();
      this.addMedicineCategory();
    },

    modalEditMedicineCategory(evt) {
      evt.preventDefault();
      this.editMedicineCategory();
    },

    resetModal(evt) {
      this.error = validation.clearValidationError(this.error);
      this.form = validation.clearForm(this.form);
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9008") {
          this.btn = true;
        }
      }
    },

    handleUploadWp(){
      if(this.form.is_upload == false){
        this.statusUpload = "Kategori Tidak DiUnggah"
      }else{
        this.statusUpload = "Kategori DiUnggah"
      }
    },

    async handleUploadAll(){
      await module.get("add-category-products");
    }
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kategori Inventory", route: "" },
      { title: "Daftar Kategori Inventory" },
    ]);
    // Get Table Data
    this.handleUploadWp();
    this.pagination();
    this.setActiveButton();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>